<template>
   <div class="account">
        <h1 class="center">专业知识资源统计页</h1>
        
        <div class="line-center wid-perc90">
            <el-table class="table"
            :data="accountTableData"
            style="width: 100%">
                <el-table-column
                    prop="account_title"
                    label="专业知识专题库名称"
                    width="180"
                    align="center">
                </el-table-column>
                <el-table-column
                    align="center"
                    label="领域"
                    width="400">
                    <template slot-scope="scope">
                        <div :class="{'bkgd-f7f7f7':(index+1)%2==0}" v-for="(item,index) in scope.row.account_industry" :key="index">
                            {{item}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="数据提供企业数量(家)"
                    width="200">
                    <template slot-scope="scope">
                        <div :class="{'bkgd-f7f7f7':(index+1)%2==0}" v-for="(item,index) in scope.row.account_enterprise" :key="index">
                            {{item}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    width="300"
                    label="数量">
                    <template slot-scope="scope"> 
                        <div :class="{'bkgd-f7f7f7':(index+1)%2==0}" v-for="(item,index) in scope.row.account_num" :key="index">
                            {{item}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    width="300"
                    label="资源总量(1PB=1024TB)"
                    align="center"
                    >
                    <template slot-scope="scope">
                        <div :class="{'bkgd-f7f7f7':(index+1)%2==0}" v-for="(item,index) in scope.row.account_total" :key="index">
                            {{item}}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div> 
</template>
<script>
export default {
    data(){
        return{
            accountTableData:[
                    {
                    account_title: '专业词条库',
                    account_industry: [
                        '网络经济',
                        '生物经济',
                        '高端制造(包括高端设备制造与新材料)',
                        '绿色低碳(包括新能源、新能源汽车、节能环保)',
                        '数字创意',
                        '总计'
                    ],
                    account_num: ['188.61万(条)','36万(条)','64万(条)','207万(条)','88万(条)','586.31万(条)'],
                    account_total:['202TB','87TB','103TB','186TB','24TB','502TB'],
                    account_enterprise:['3074','425','635','101','91','4326']
                }, {
                    account_title: '全球产品库',
                    account_industry: [
                        '网络经济',
                        '生物经济',
                        '高端制造(包括高端设备制造与新材料)',
                        '绿色低碳(包括新能源、新能源汽车、节能环保)',
                        '数字创意',
                        '总计'
                    ],
                    account_num: ['1003万(条)','2136万(条)','860万(条)','365万(条)','1636万(条)','6000万(条)'],
                    account_total:['85TB','36TB','24TB','25TB','55TB','225TB'],
                    account_enterprise:['1368','432','11608','1336','986','15730']
                }, {
                    account_title: '期刊文献库',
                    account_industry: [
                        '网络经济',
                        '生物经济',
                        '高端制造(包括高端设备制造与新材料)',
                        '绿色低碳(包括新能源、新能源汽车、节能环保)',
                        '数字创意',
                        '总计'
                    ],
                    account_num: ['302万(条)','411万(条)','1001.015万(条)','90.1万(条)','187万(条)','1991.115万(条)'],
                    account_total:['48TB','34TB','108TB','18TB','7TB','215TB'],
                    
                    account_enterprise:['37','41','635','11','9','733']
                }, {
                    account_title: '知识头条库',
                    account_industry: [
                        '网络经济',
                        '生物经济',
                        '高端制造(包括高端设备制造与新材料)',
                        '绿色低碳(包括新能源、新能源汽车、节能环保)',
                        '数字创意',
                        '总计'
                    ],
                    account_num: ['6.1万(条)','15.05万(条)','136万(条)','80.05万(条)','64万(条)','301.2万(条)'],
                    account_total:['3TB','6.5TB','11TB','9TB','7.5TB','36TB'],
                    account_enterprise:['360','425','635','101','91','1612'],
                }, {
                    account_title: '智能问答库',
                    account_industry: [
                        '网络经济',
                        '生物经济',
                        '高端制造(包括高端设备制造与新材料)',
                        '绿色低碳(包括新能源、新能源汽车、节能环保)',
                        '数字创意',
                        '总计'
                    ],
                    account_num: ['188.61万(条)','36万(条)','64万(条)','207万(条)','88万(条)','586.31万(条)'],
                    account_total:['202TB','87TB','103TB','186TB','24TB','502TB'],
                    account_enterprise:['138','425','635','101','91','1390']
                }, 
            ],
        }
    }
}
</script>
<style scoped>
/* public style */
.center{
    text-align: center;
}
.bkgd-f7f7f7{
    background-color: #f7f7f7;
}
.wid-perc90{
    width: 90%;
}
.line-center{
    margin: 0 auto;
}
/* private style */

/* reset style */
.account >>> .el-table .cell {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    /* overflow: visible; */
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    line-height: 23px;
    padding-right: 0;
    padding-left: 0;
}

</style>